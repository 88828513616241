import React, { useContext, useState, Fragment } from "react";
import { AlignCenter } from "react-feather";
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElements";
import CheckContext from "../../_helper/Customizer";
import { COLORS } from "../../static";

const Leftbar = () => {
  const { mixLayout, toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(!toggle);
  };

  return (
    <Fragment>
      <div className="main-header-left">
          <div className="logo-wrapper">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <div className="flex justify-between items-center">
                <img src="/logo.png" alt="logo" width="50" />
                <span className="ml-2 text-lg text-black leading-tight font-bold">
                  Power Academy
                </span>
              </div>
            </Link>
          </div>
        <div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
          <AlignCenter
            color={COLORS.primary}
            className="status_toggle middle"
            id="sidebar-toggle"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Leftbar;
