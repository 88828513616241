import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
} from "../Constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import man from "../assets/images/dashboard/1.png";
import SocialAuth from "./Tabs/LoginTab/SocialAuth";
import { Button, Center, PasswordInput } from "@mantine/core";

const Login = ({ selected }) => {
  const [email, setEmail] = useState("test@gmail.com");
  const [password, setPassword] = useState("test123");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValue(man);
    setName("Emay Walter");
    setEmail("test@gmail.com");
    setPassword("test123");
    if (email === "test@gmail.com" && password === "test123") {
      localStorage.setItem("login", true);
      window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
      setLoading(false);
    } else {
      toast.error("Incorrect Password or Username!");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="p-0 container-fluid">
        <Row>
          <Col className="col-12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <Center>
                    <img src="/logo.png" alt="logo" width={180} className="mb-10" />
                  </Center>
                  <H4>Welcome Coach !</H4>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      className="form-control"
                      type="email"
                      required=""
                      onChange={(e) => setEmail(e.target.value)}
                      defaultValue={email}
                    />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <PasswordInput
                      defaultValue={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <div className="form-group mt-4">
                    <Button
                      fullWidth
                      size="lg"
                      loading={loading}
                      onClick={(e) => loginAuth(e)}
                      disabled={loading || !email || !password}
                    >
                      Login
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Login;
