import React, { useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H5, H6, P } from "../AbstractElements";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import api from "../api";
import DataTable from "react-data-table-component";
import { Badge, Button, TextInput } from "@mantine/core";
import { Edit, Trash2 } from "react-feather";
import { getStatusColor } from "../utils/functions";
import { IconInputSearch } from "@tabler/icons-react";

const columns = [
  {
    name: "Name",
    selector: (row) => (
      <div className="flex flex-col my-2">
        <h6 className="m-0">
          {row.firstName} {row.lastName}{" "}
          <Badge size="sm" color={row.deleted ? "red" : "green"}>
            {row.deleted ? "Inactive" : "Active"}
          </Badge>
        </h6>
        <span>{row.email}</span>
        <span>{row.phoneNumber}</span>
      </div>
    ),
    width: "300px",
  },
  {
    name: "Type",
    selector: (row) =>
      row.type === "Guest" ? (
        <Badge color="grey">Guest</Badge>
      ) : (
        <Badge color="black">Student</Badge>
      ),
  },
  {
    name: "Membership Status",
    selector: (row) =>
      row.type === "Student" ? (
        <Badge color={getStatusColor(row.memberships[0]?.status)}>
          {row.memberships[0]?.status}
        </Badge>
      ) : (
        <span>-</span>
      ),
  },
  {
    name: "Nr of memberships",
    selector: (row) => row.memberships.length,
  },
  {
    name: "Classes (Attended / Enrolled)",
    selector: (row) => (
      <P>
        {row.enrolledClasses.length} / {row.attendedClasses.length}
      </P>
    ),
  },
  {
    name: "Rank",
    selector: (row) =>
      row?.type === "Student" ? (
        <Badge color={row.rank?.rankInfo?.color}>
          {row.rank?.rankInfo?.name}
        </Badge>
      ) : (
        <span>No rank</span>
      ),
  },
  {
    name: "Actions",
    selector: (row) => (
      <div>
        <Button size="compact-sm" variant="outline" className="mx-2">
          <Edit size={13} />
        </Button>
        <Button size="compact-sm" variant="outline" color="red">
          <Trash2 size={13} />
        </Button>
      </div>
    ),
  },
];

const Students = () => {
  const [students, setStudents] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStudents = students.filter((student) => {
    const fullName = `${student.firstName} ${student.lastName}`.toLowerCase();
    const phoneNumber = student.phoneNumber.toLowerCase();
    const email = student.email.toLowerCase();
    const rankName = student.rank?.rankInfo?.name.toLowerCase() || "";
    const searchTermLower = searchTerm.toLowerCase();

    return (
      fullName.includes(searchTermLower) ||
      phoneNumber.includes(searchTermLower) ||
      email.includes(searchTermLower) ||
      rankName.includes(searchTermLower)
    );
  });

  const { isFetching } = useQuery({
    queryKey: ["getStudents"],
    queryFn: async () => {
      try {
        const response = await api.post("/student/list");
        setStudents(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  return (
    <>
      <Breadcrumbs mainTitle="Manage students" title="Students" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <TextInput
                  type="search"
                  className="w-1/3"
                  leftSection={<IconInputSearch />}
                  placeholder="Type to search for a student by name, email, phone or rank..."
                  onChange={handleSearch}
                />
              </CardHeader>
              <CardBody>
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={filteredStudents}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Students;
