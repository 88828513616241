import React, { useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Card as MantineCard,
  Menu,
  rem,
  Text,
} from "@mantine/core";
import { Breadcrumbs, H1, H2, H5, P } from "../AbstractElements";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { toast } from "sonner";
import { Paper, SimpleGrid } from "@mantine/core";
import { Edit, PlusCircle } from "react-feather";
import { IconDots, IconTrash } from "@tabler/icons-react";
import Belt from "../Components/Belt/Belt";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);

  const { isFetching } = useQuery({
    queryKey: ["getFaqs"],
    queryFn: async () => {
      try {
        const response = await api.post("/faq/list");
        setFaqs(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  return (
    <>
      <Breadcrumbs mainTitle="Manage FAQ" title="FAQ" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Group justify="flex-end">
                  <Button
                    variant="outline"
                    leftSection={<PlusCircle size={15} />}
                  >
                    Add FAQ
                  </Button>
                </Group>
              </CardHeader>
              <CardBody>
                <SimpleGrid cols={3}>
                  {faqs?.map((faq, key) => (
                    <MantineCard
                      key={key}
                      shadow="sm"
                      padding={"sm"}
                      withBorder
                    >
                      <Group justify="flex-end">
                        {faq?.deleted ? (
                          <Badge className="mx-2" color="red">
                            Inactive
                          </Badge>
                        ) : (
                          <Badge className="mx-2" color="green">
                            Active
                          </Badge>
                        )}
                        <Menu withinPortal position="bottom-end" shadow="sm">
                          <Menu.Target>
                            <ActionIcon variant="subtle" color="gray">
                              <IconDots
                                style={{ width: rem(16), height: rem(16) }}
                              />
                            </ActionIcon>
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Item
                              leftSection={
                                <Edit
                                  style={{ width: rem(14), height: rem(14) }}
                                />
                              }
                            >
                              Edit
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </Group>
                      <H5>Q: {faq?.question}</H5>
                      <P>A: {faq?.answer}</P>
                    </MantineCard>
                  ))}
                </SimpleGrid>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Faq;
