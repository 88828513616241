import React, { useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H5, P } from "../AbstractElements";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import api from "../api";
import DataTable from "react-data-table-component";
import { Badge, Button, TextInput } from "@mantine/core";
import { Edit, Trash2 } from "react-feather";
import { getStatusColor } from "../utils/functions";
import { IconInputSearch } from "@tabler/icons-react";
import moment from "moment";

const columns = [
  {
    name: "Student Name",
    selector: (row) => `${row.student.firstName} ${row.student.lastName}`,
  },
  {
    name: "Membership status",
    selector: (row) => (
      <Badge color={getStatusColor(row.status)}>{row.status}</Badge>
    ),
  },
  {
    name: "Membership Ttype",
    selector: (row) => (
      <P>
        {row.membershipType.duration.number} {row.membershipType.duration.type}
      </P>
    ),
  },
  {
    name: "Expiry date",
    selector: (row) => moment(row.expiryDate).format("DD MMMM YYYY"),
  },
  {
    name: "Actions",
    selector: (row) => (
      <div>
        <Button size="compact-sm" variant="outline" className="mx-2">
          <Edit size={13} />
        </Button>
        <Button size="compact-sm" variant="outline" color="red">
          <Trash2 size={13} />
        </Button>
      </div>
    ),
  },
];

const Memberships = () => {
  const [memberships, setMemberships] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredMemberships = memberships.filter((membership) => {
    const fullName =
      `${membership?.student.firstName} ${membership?.student.lastName}`.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();

    return fullName.includes(searchTermLower);
  });

  const { isFetching } = useQuery({
    queryKey: ["getMemberships"],
    queryFn: async () => {
      try {
        const response = await api.post("/membership/list");
        setMemberships(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  return (
    <>
      <Breadcrumbs mainTitle="Manage students" title="Students" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <TextInput
                  type="search"
                  className="w-1/3"
                  leftSection={<IconInputSearch />}
                  placeholder="Type to search for a student by name, email, phone or rank..."
                  onChange={handleSearch}
                />
              </CardHeader>
              <CardBody>
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={filteredMemberships}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Memberships;
