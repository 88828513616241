import React, { useState } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Breadcrumbs } from "../AbstractElements";
import DataTable from "react-data-table-component";
import api from "../api";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import { Badge, Button } from "@mantine/core";
import { Edit, Trash2 } from "react-feather";

const columns = [
  {
    name: "Name",
    selector: (row) => `${row.firstName} ${row.lastName}`,
  },
  {
    name: "Status",
    selector: (row) =>
      row.active ? (
        <Badge color={"green"}>Active</Badge>
      ) : (
        <Badge color={"red"}>Inactive</Badge>
      ),
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Actions",
    selector: (row) => (
      <div>
        <Button size="compact-sm" variant="outline" className="mx-2">
          <Edit size={13} />
        </Button>
        <Button size="compact-sm" variant="outline" color="red">
          <Trash2 size={13} />
        </Button>
      </div>
    ),
  },
];

const Administrators = () => {
  const [admins, setAdmins] = useState([]);

  const { isFetching } = useQuery({
    queryKey: ["getAdministrators"],
    queryFn: async () => {
      try {
        const response = await api.post("/admin/list");
        setAdmins(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  return (
    <>
      <Breadcrumbs mainTitle="Manage administrators" title="Administrators" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={admins}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Administrators;
