import moment from "moment";

export const getStatusColor = (status) => {
  switch (status) {
    case "Pending":
      return "#ffc107"; // Yellow
    case "Active":
      return "#28a745"; // Green
    case "Inactive":
      return "#dc3545"; // Red
    case "Overdue":
      return "#6c757d"; // Gray
    default:
      return "#343a40"; // Dark Gray (for unknown status)
  }
};

export const getYoutubeVideoId = url => {
  // Split the URL by "/"
  const parts = url.split('/');
  // Take the last component which contains the video ID
  const videoIdComponent = parts[parts.length - 1];
  // Split the video ID component by "?" to get the ID part
  const videoIdParts = videoIdComponent.split('?');
  // Take the first part after splitting by "?"
  const videoId = videoIdParts[0];
  console.log({videoId});
  return videoId;
};

export const relativeDate = inputDate => {
  let date = moment(inputDate);
  let REFERENCE = moment(); // fixed just for testing, use moment();
  let TODAY = REFERENCE.clone().startOf('day');
  let YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

  if (date.isSame(TODAY, 'd')) {
    let hours = REFERENCE.diff(date, 'hours');
    let minutes = REFERENCE.diff(date, 'minutes');
    if (hours === 0) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else {
      return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${
        minutes !== 1 ? 's' : ''
      } ago`;
    }
  } else if (date.isSame(YESTERDAY, 'd')) {
    return 'Yesterday';
  } else {
    let nbrDays = REFERENCE.diff(date, 'd');
    return `${nbrDays} day${nbrDays !== 1 ? 's' : ''} ago`;
  }
};
