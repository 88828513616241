import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LI, UL, H6 } from "../../AbstractElements";
import { MENUITEMS } from "./Menu";
import { Label } from "reactstrap";
import { useRecoilState } from "recoil";
import { selectedMenuAtom } from "../../recoil/atoms";

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive }) => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useRecoilState(selectedMenuAtom);

  const toggletNavActive = (item) => {
    setSelectedMenu(item.id);
  };

  return (
    <Fragment>
      <UL attrUL={{ className: "nav-menu custom-scrollbar mt-4" }}>
        <LI attrLI={{ className: "back-btn" }}>
          <div className="mobile-back text-end">
            <span>Back</span>
            <i className="fa fa-angle-right ps-2"></i>
          </div>
        </LI>

        {MENUITEMS.map((Item, i) => (
          <Fragment key={i}>
            <LI attrLI={{ className: "sidebar-main-title" }}>
              <div>
                <H6>{t(Item.menutitle)}</H6>
              </div>
            </LI>
            {Item.Items.map((menuItem, i) => (
              <LI attrLI={{ className: "dropdown" }} key={i}>
                {menuItem.type === "link" && (
                  <Link
                    to={menuItem.path}
                    id="nav-link"
                    className={`nav-link menu-title ${
                      menuItem.id === selectedMenu
                        ? "text-white bg-black"
                        : "text-gray-900"
                    } hover:text-white hover:bg-black`}
                    style={{ display: "flex" }}
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    {menuItem.icon !== undefined && <menuItem.icon />}

                    <span>{t(menuItem.title)}</span>

                    {menuItem.badge && (
                      <Label className={menuItem.badge}>
                        {menuItem.badgetxt}
                      </Label>
                    )}
                  </Link>
                )}
                {menuItem.children && (
                  <UL
                    attrUL={{
                      className: "simple-list sidebar-submenu",
                    }}
                  >
                    <UL
                      attrUL={{
                        className: "nav-submenu menu-content",
                        style: menuItem.active
                          ? sidebartoogle
                            ? {
                                opacity: 1,
                                transition: "opacity 500ms ease-in",
                              }
                            : { display: "block" }
                          : { display: "none" },
                      }}
                    >
                      {menuItem.children.map((childrenItem, index) => {
                        return (
                          <LI key={index}>
                            {childrenItem.type === "sub" && (
                              <a
                                href="javascript"
                                className={`${
                                  childrenItem.active ? "active" : ""
                                }`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  toggletNavActive(childrenItem);
                                }}
                              >
                                {t(childrenItem.title)}
                                <div className="according-menu">
                                  {childrenItem.active ? (
                                    <i className="fa fa-caret-down"></i>
                                  ) : (
                                    <i className="fa fa-caret-right"></i>
                                  )}{" "}
                                </div>
                              </a>
                            )}
                            {childrenItem.type === "link" && (
                              <Link
                                to={childrenItem.path}
                                className={`${
                                  childrenItem.active ? "active" : ""
                                }`}
                                onClick={() => toggletNavActive(childrenItem)}
                              >
                                {t(childrenItem.title)}
                              </Link>
                            )}
                            {childrenItem.children && (
                              <UL
                                attrUL={{
                                  className:
                                    "simple-list nav-sub-childmenu submenu-content",
                                  style: childrenItem.active
                                    ? { display: "block" }
                                    : { display: "none" },
                                }}
                              >
                                {childrenItem.children.map(
                                  (childrenSubItem, key) => (
                                    <LI key={key}>
                                      {childrenSubItem.type === "link" && (
                                        <Link
                                          to={childrenSubItem.path}
                                          className={`${
                                            childrenSubItem.active
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            toggletNavActive(childrenSubItem)
                                          }
                                        >
                                          {t(childrenSubItem.title)}
                                        </Link>
                                      )}
                                    </LI>
                                  )
                                )}
                              </UL>
                            )}
                          </LI>
                        );
                      })}
                    </UL>
                  </UL>
                )}
              </LI>
            ))}
          </Fragment>
        ))}
      </UL>
    </Fragment>
  );
};
export default SidebarMenuItems;
