import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { Btn, LI } from "../../../AbstractElements";
import { LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mantine/core";

const LogoutClass = () => {
  const history = useNavigate();
  const Logout = () => {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");

    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.setItem("authenticated", false);
    history(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown p-0", onClick: Logout }}>
        <Button onClick={Logout} leftSection={<LogOut />} variant="light">
          Log out
        </Button>
      </LI>
    </Fragment>
  );
};

export default LogoutClass;
