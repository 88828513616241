import React from "react";
import { COLORS } from "../../static";

const Belt = ({ color, notchColor }) => {
  const styles = {
    container: {
      width: "50%",
      borderWidth: 1,
      borderColor: COLORS.lightBlack,
      backgroundColor: color ?? COLORS.white,
      borderRadius: 2,
      height: 15,
      marginTop: 4,
      marginBottom: 12,
    },
    notch: {
      marginTop: -1,
      width: "20%",
      backgroundColor: notchColor ?? COLORS.lightBlack,
      height: 15,
      marginLeft: "10%",
    },
  };

  return (
    <div style={{ ...styles.container }}>
      <div style={styles.notch} />
    </div>
  );
};

export default Belt;
