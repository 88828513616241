import React, { useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H3, H5, P } from "../AbstractElements";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { toast } from "sonner";
import {
  Avatar,
  Badge,
  Button,
  Center,
  Drawer,
  Group,
  Table,
} from "@mantine/core";
import { Check, CheckCircle, RefreshCcw, Users } from "react-feather";
import { useDisclosure } from "@mantine/hooks";
import Belt from "../Components/Belt/Belt";
import "@mantine/tiptap/styles.css";

const localizer = momentLocalizer(moment);

const CustomEvent = ({ event }) => (
  <div className="flex flex-col">
    <p className="mb-0 font-bold">{event.title}</p>
    <p className="mb-1 text-sm font-light">{event.description}</p>
    <Group justify="space-between" className="mt-2">
      <Badge
        radius={"xl"}
        color="white"
        size="xs"
        leftSection={
          event.additionalData.status === "Finished" ? (
            <Check color="black" size={11} />
          ) : (
            <RefreshCcw color="black" size={11} />
          )
        }
      >
        <p className="text-gray-900 text-[11px]">
          {event.additionalData.status}
        </p>
      </Badge>
      <Badge
        radius={"xl"}
        size="xs"
        color="white"
        leftSection={<Users color="black" size={11} />}
      >
        <p className="text-gray-900 text-[11px]">
          {event.additionalData.attendedStudents?.length} /{" "}
          {event.additionalData.enrolledStudents?.length}
        </p>
      </Badge>
    </Group>
  </div>
);

const Classes = () => {
  const [classes, setClasses] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [drawerOpen, drawerHandlers] = useDisclosure(false);

  const { isFetching } = useQuery({
    queryKey: ["getClasses"],
    queryFn: async () => {
      try {
        const response = await api.post("/class/list");
        let results = response.data.map((el) => {
          return {
            title: el.name,
            beginsAt: new Date(el.beginsAt),
            finishesAt: new Date(el.finishesAt),
            description: el.session.type,
            additionalData: el,
          };
        });
        setClasses(results);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const handleClickEvent = (event) => {
    setSelectedEvent(event.additionalData);
    drawerHandlers.open();
  };

  const generateRows = (attendedStudents, enrolledStudents) => {
    const maxLength = Math.max(
      attendedStudents?.length,
      enrolledStudents?.length
    );

    const rows = [];

    for (let i = 0; i < maxLength; i++) {
      const attendedStudent = attendedStudents[i] || null;
      const enrolledStudent = enrolledStudents[i] || null;

      rows.push(
        <Table.Tr key={`${i}`}>
          <Table.Td>
            {attendedStudent && (
              <div className="flex items-center w-full">
                <Avatar
                  src={null}
                  variant="outline"
                  color="black"
                  radius={"lg"}
                />
                <div className="flex flex-col ml-2 w-full">
                  <p className="font-medium text-gray-800">
                    {attendedStudent.firstName} {attendedStudent.lastName}
                  </p>
                  <Belt
                    color={attendedStudent?.rank?.rankInfo?.color}
                    notchColor={
                      attendedStudent?.rank?.rankInfo?.name === "Black belt"
                        ? "red"
                        : "black"
                    }
                  />
                </div>
              </div>
            )}
          </Table.Td>
          <Table.Td>
            {enrolledStudent && (
              <div className="flex items-center w-full">
                <Avatar
                  src={null}
                  variant="outline"
                  color="black"
                  radius={"lg"}
                />
                <div className="flex flex-col ml-2  w-full">
                  <p className="font-medium text-gray-800">
                    {enrolledStudent.firstName} {enrolledStudent.lastName}
                  </p>
                  <Belt
                    color={enrolledStudent?.rank?.rankInfo?.color}
                    notchColor={
                      enrolledStudent?.rank?.rankInfo?.name === "Black belt"
                        ? "red"
                        : "black"
                    }
                  />
                </div>
              </div>
            )}
          </Table.Td>
        </Table.Tr>
      );
    }

    return rows;
  };

  const rows = generateRows(
    selectedEvent?.attendedStudents,
    selectedEvent?.enrolledStudents
  );

  return (
    <>
      <Breadcrumbs mainTitle="Classes" title="Classes" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>Gym Calendar</H5>
                <span>You can find here this week's and the past classes</span>
              </CardHeader>
              <CardBody>
                <Calendar
                  defaultView="week"
                  showAllEvents={false}
                  localizer={localizer}
                  events={classes}
                  startAccessor="beginsAt"
                  endAccessor="finishesAt"
                  style={{ height: 700 }}
                  components={{
                    week: { event: CustomEvent },
                    day: { event: CustomEvent },
                  }}
                  views={["month", "week", "day"]}
                  min={new Date(0, 0, 0, 6, 0, 0)}
                  max={new Date(0, 0, 0, 23, 0, 0)}
                  onSelectEvent={handleClickEvent}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Drawer
        opened={drawerOpen}
        title={`${moment(selectedEvent?.beginsAt).format("ddd DD/MM/YY")} - ${
          selectedEvent?.name
        } [${selectedEvent?.session?.startTime} - ${
          selectedEvent?.session?.endTime
        }]`}
        size={"lg"}
        withCloseButton
        onClose={() => drawerHandlers.close()}
        position="right"
        styles={{
          title: {
            fontSize: "1.5rem",
            fontWeight: "bold",
          },
        }}
      >
        <h4>{selectedEvent?.session?.type}</h4>
        <Group justify="space-between">
          <h6 className="w-3/4">{selectedEvent?.description}</h6>
          <Badge
            radius={"xl"}
            leftSection={
              selectedEvent?.status === "Finished" ? (
                <Check size={11} />
              ) : (
                <RefreshCcw size={11} />
              )
            }
          >
            {selectedEvent?.status}
          </Badge>
        </Group>
        <div className="mt-2">
          <p>
            <strong>Coach :</strong> {selectedEvent?.coach.firstName}{" "}
            {selectedEvent?.coach.lastName}
          </p>
        </div>
        <div className="mt-3">
          <p className="flex items-baseline">
            <h3 className="mx-2">{selectedEvent?.attendedStudents?.length}</h3>{" "}
            attended students /{" "}
            <h3 className="mx-2">{selectedEvent?.enrolledStudents?.length}</h3>{" "}
            enrolled students
          </p>
          {!(
            selectedEvent?.attendedStudents.length === 0 &&
            selectedEvent?.enrolledStudents?.length === 0
          ) && (
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Attended students</Table.Th>
                  <Table.Th>Enrolled students</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          )}
        </div>
        <div className="mt-4">
          <h4>Session Recap</h4>
        </div>
        <Center>
          <Button
            className="mt-5"
            variant="outline"
            onClick={() => drawerHandlers.close()}
          >
            Save
          </Button>
        </Center>
      </Drawer>
    </>
  );
};

export default Classes;
