import React, { useState } from "react";
import { Breadcrumbs } from "../AbstractElements";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import {
  Button,
  Group,
  Modal,
  Container as MantineContainer,
  Textarea,
  TextInput,
  Center,
  Divider,
  Menu,
  rem,
  ActionIcon,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Edit, PlusSquare, ThumbsUp, Video, X, Youtube } from "react-feather";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import { toast } from "sonner";
import {
  IconArchive,
  IconDots,
  IconLoader,
  IconTrash,
} from "@tabler/icons-react";
import YouTube from "react-youtube";
import { getYoutubeVideoId, relativeDate } from "../utils/functions";

const Posts = () => {
  const [createModalOpen, createModalHandlers] = useDisclosure(false);
  const [addVideoActive, setAddVideoActive] = useState(false);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [newPost, setNewPost] = useState(false);
  const queryClient = useQueryClient();

  const { isFetching } = useQuery({
    queryKey: ["getPosts", page],
    queryFn: async () => {
      try {
        const response = await api.get(`/post/list?page=${page}`);
        if (page > 1) {
          setPosts([...posts, ...response?.data?.data]);
        } else {
          setPosts(response?.data?.data);
          setFirstLoaded(true);
        }
        setTotal(response.data.total);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const createPost = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post("/post/new", data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["getPosts"] });
      toast.success("Your post has been created successully");
      createModalHandlers.close();
    },
  });

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const handlePost = () => {
    createPost.mutate(newPost);
  };

  const handleEdit = () => {};

  const handleDelete = () => {};
  return (
    <>
      <Breadcrumbs mainTitle="Social Feed" title="Feed" />
      <MantineContainer size={"md"}>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <Group justify="center">
                    <Button
                      fullWidth
                      leftSection={<PlusSquare size={14} />}
                      size="lg"
                      variant="outline"
                      radius={"xl"}
                      onClick={createModalHandlers.open}
                    >
                      What's on your mind, Coach ?
                    </Button>
                  </Group>
                </CardHeader>
                <CardBody>
                  {isFetching && !firstLoaded && (
                    <div className="h-12">
                      <Center>
                        <IconLoader size={14} className="animate-spin" />
                      </Center>
                    </div>
                  )}
                  {(!isFetching || firstLoaded) &&
                    posts?.map((post, key) => (
                      <>
                        <div key={key} className="py-3">
                          <Group justify="space-between">
                            <p className="text-md">
                              <strong>Posted by :</strong> Admin
                            </p>
                            <div className="flex">
                              <p className="text-gray-500 mx-3">
                                {relativeDate(post.createdAt)}
                              </p>
                              <Menu
                                withinPortal
                                position="bottom-end"
                                shadow="sm"
                              >
                                <Menu.Target>
                                  <ActionIcon variant="subtle" color="gray">
                                    <IconDots
                                      style={{
                                        width: rem(16),
                                        height: rem(16),
                                      }}
                                    />
                                  </ActionIcon>
                                </Menu.Target>

                                <Menu.Dropdown>
                                  <Menu.Item
                                    onClick={() => handleEdit(post)}
                                    leftSection={
                                      <Edit
                                        style={{
                                          width: rem(14),
                                          height: rem(14),
                                        }}
                                      />
                                    }
                                  >
                                    Edit post
                                  </Menu.Item>

                                  <Menu.Item
                                    onClick={() => handleDelete(post)}
                                    leftSection={
                                      <IconTrash
                                        style={{
                                          width: rem(14),
                                          height: rem(14),
                                        }}
                                      />
                                    }
                                    color="red"
                                  >
                                    Delete post
                                  </Menu.Item>
                                </Menu.Dropdown>
                              </Menu>
                            </div>
                          </Group>
                          {post?.link && (
                            <Center className="my-4">
                              <YouTube
                                videoId={getYoutubeVideoId(post?.link)}
                                opts={opts}
                              />
                            </Center>
                          )}
                          <p className="mt-2">{post?.text}</p>
                          <Button
                            variant="transparent"
                            size="compact-sm"
                            className="mt-2"
                            rightSection={<ThumbsUp size={14} />}
                          >
                            {post?.likes?.length}
                          </Button>
                        </div>
                        <Divider />
                      </>
                    ))}
                  {firstLoaded && total > posts?.length && (
                    <Group justify="center">
                      <Button
                        leftSection={<IconLoader size={14} />}
                        size="lg"
                        variant="outline"
                        radius={"lg"}
                        onClick={() => setPage(page + 1)}
                        loading={isFetching}
                        className="mt-5"
                      >
                        Load more
                      </Button>
                    </Group>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </MantineContainer>
      <Modal
        opened={createModalOpen}
        onClose={createModalHandlers.close}
        size="lg"
        title="Create post"
        centered
        styles={{
          title: {
            fontSize: "1.5rem",
            fontWeight: "bold",
          },
        }}
      >
        <Textarea
          autoFocus
          rows={6}
          placeholder="What's on your mind, Coach ?"
          size="md"
          onChange={(e) =>
            setNewPost({
              ...newPost,
              text: e.target.value,
            })
          }
        />
        {addVideoActive && (
          <TextInput
            className="mt-1"
            type="url"
            placeholder="Paste here the share link you copied from YouTube"
            rightSection={
              <X onClick={() => setAddVideoActive(false)} size={14} />
            }
            onChange={(e) =>
              setNewPost({
                ...newPost,
                link: e.target.value,
              })
            }
          />
        )}
        {!addVideoActive && (
          <Group justify="flex-end" mt="md">
            <Button
              onClick={() => setAddVideoActive(true)}
              size="compact-sm"
              leftSection={<Youtube size={14} />}
            >
              Add a video
            </Button>
          </Group>
        )}
        <Group justify="space-between" mt="md" className="mt-3">
          <Button variant="outline">Cancel</Button>
          <Button size="lg" onClick={handlePost} loading={createPost.isPending} disabled={!newPost?.text}>
            Post
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Posts;
