import React from "react";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import { colorsTuple, createTheme, MantineProvider } from "@mantine/core";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";

const theme = createTheme({
  colors: {
    primary: colorsTuple("#030406"),
  },
  primaryColor: "primary",
});

const queryClient = new QueryClient();

const App = () => (
  <div className="App">
    <CustomizerProvider>
      <AnimationThemeProvider>
        <MantineProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <RecoilRoot>
              <Toaster />
              <Routers />
            </RecoilRoot>
          </QueryClientProvider>
        </MantineProvider>
      </AnimationThemeProvider>
    </CustomizerProvider>
  </div>
);

export default App;
