import { atom } from "recoil";
const { recoilPersist } = require("recoil-persist");
const { persistAtom } = recoilPersist({
  key: "recoil-persist", // this key is using to store data in local storage
  storage: localStorage, // configurate which stroage will be used to store the data
});

export const connectedUserAtom = atom({
  key: "connectedUser",
  default: {
    connected: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const selectedMenuAtom = atom({
    key: "selectedMenu",
    default: "dashboard",
  });