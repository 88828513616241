// exports.host = "http://localhost:5002";
exports.host = 'https://api.poweracademy.tn';

exports.COLORS = {
  primary: "#030406",
  black: "#000",
  darkGrey: "#3E4958",
  background: "#F8F8F8",
  grey: "#bcbfc6",
  lightGrey: "#e0e6e8",
  white: "#FFF",
  chip: "#EEEEEE",
  lightBlack: "#26272D",
  success: "#090",
  blue: "#1A428A",
  purple: "#61215C",
  brown: "#4B351D",
  gold: "gold",
  silver: "silver",
  bronze: "#c96f2a",
  approvedBackground: "#c7ebd1",
  approvedText: "#045e45",
  pendingBackground: "#fcddc7",
  pendingText: "#8f3414",
  rejectedBackground: "#fadcd9",
  rejectedText: "#a1150b",
};
